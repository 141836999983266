import React, { useState } from "react";
import Cookies from "universal-cookie";
import "bootstrap/dist/css/bootstrap.min.css";
import { Button, FormGroup, FormControl, FormLabel } from "react-bootstrap";
import "./Login.css";

//const PROXY = '127.0.0.1:8080/';
const SERVER_URL = "https://api.aerotoulousain.fr";

export default function Login({ history }) {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const validateForm = () => {
    return email.length > 0 && password.length > 0;
  };

  const handleChangeEmail = (event) => {
    setEmail(event.target.value);
  };

  const handleChangePassword = (event) => {
    setPassword(event.target.value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    fetch(SERVER_URL + "/login", {
      method: "POST",
      body: JSON.stringify({ email: email, password: password }),
      headers: new Headers({
        Accept: "application/json",
        "Content-Type": "application/json",
        Origin: "",
      }),
    })
      .then((res) => {
        if (res.status === 200) {
          return res.json();
        }
      })
      .then((resJson) => {
        if (resJson === undefined) {
          return;
        }
        const cookies = new Cookies();
        var token = resJson.Token.value;
        cookies.set("token", token, { path: "/" });
        console.log(cookies.get("token")); //
        history.push("/");
      });
  };

  return (
    <div className="Login">
      <form onSubmit={handleSubmit}>
        <FormGroup controlId="email" bsSize="large">
          <FormLabel>Email</FormLabel>
          <FormControl
            autoFocus
            type="email"
            value={email}
            onChange={handleChangeEmail}
          />
        </FormGroup>
        <FormGroup controlId="password" bsSize="large">
          <FormLabel>Password</FormLabel>
          <FormControl
            value={password}
            onChange={handleChangePassword}
            type="password"
          />
        </FormGroup>
        <Button block bsSize="large" disabled={!validateForm()} type="submit">
          Login
        </Button>
      </form>
    </div>
  );
}
