import React, { Component } from "react";
import { Button, FormGroup, FormControl, FormLabel } from "react-bootstrap";
import Cookies from "js-cookie";
import { Link } from "react-router-dom";
import Switch from "react-switch";
import "./Raspberry.css";
import JsonComponent from "./components/JsonComponent";
import raspberryHandler from "./api/raspberry";

const SERVER_URL = "https://api.aerotoulousain.fr";

function isArray(what) {
  return Object.prototype.toString.call(what) === "[object Array]";
}

export default class Dashboard extends Component {
  constructor(props) {
    super(props);

    console.log(props);
    let edit = true;
    const arr = props.location.search.split("=");
    if (arr.length === 2 && arr[0] === "?edit" && arr[1] === "false") {
      edit = false;
    }
    this.state = {
      token: Cookies.get("token"),
      raspberry: {
        id: this.props.match.params.id,
        name: "",
      },
      title: "",
      type: "",
      code: "",
      inputTitle: "",
      inputCode: "",
      name: "",
      password: "",
      raspberries: [],
      metrics: [],
      inputs: [],
      date: new Date(),
      edit: edit,
    };
    this.deleteMetric = this.deleteMetric.bind(this);
  }

  componentDidMount() {
    this.timerID = setInterval(() => this.tick(), 1000);
  }

  createMetric = (event) => {
    event.preventDefault();
    fetch(SERVER_URL + "/raspberry/" + this.props.match.params.id + "/metric", {
      method: "POST",
      body: JSON.stringify({
        title: this.state.title,
        code: this.state.code,
        type: this.state.type,
      }),
      headers: new Headers({
        token: this.state.token,
        Accept: "application/json",
        "Content-Type": "application/json",
        Origin: "",
      }),
    })
      .then((res) => {
        if (res.status === 200) {
          return res.json();
        }
      })
      .then((resJson) => {});
  };

  createInput = (event) => {
    event.preventDefault();
    fetch(SERVER_URL + "/raspberry/" + this.props.match.params.id + "/input", {
      method: "POST",
      body: JSON.stringify({
        title: this.state.inputTitle,
        code: this.state.inputCode,
      }),
      headers: new Headers({
        token: this.state.token,
        Accept: "application/json",
        "Content-Type": "application/json",
        Origin: "",
      }),
    })
      .then((res) => {
        if (res.status === 200) {
          return res.json();
        }
      })
      .then((resJson) => {});
  };

  componentWillUnmount() {
    clearInterval(this.timerID);
  }

  validateForm() {
    return (
      this.state.title.length > 0 &&
      this.state.type.length > 0 &&
      this.state.code.length > 0
    );
  }

  validateInputForm() {
    return this.state.inputTitle.length > 0 && this.state.inputCode.length > 0;
  }

  tick() {
    this.reloadRaspberryMetrics(this.props.match.params.id);
    this.loadInputs(this.props.match.params.id);
  }

  componentWillMount = (event) => {
    this.reloadRaspberry(this.props.match.params.id);
    this.reloadRaspberryMetrics(this.props.match.params.id);
    this.loadInputs(this.props.match.params.id);
  };

  reloadRaspberry(id) {
    fetch(SERVER_URL + "/raspberry/" + id, {
      method: "GET",
      headers: new Headers({
        token: this.state.token,
        Accept: "application/json",
        "Content-Type": "application/json",
        Origin: "",
      }),
    })
      .then((res) => {
        if (res.status === 200) {
          return res.json();
        }
      })
      .then((raspberry) => {
        this.setState({ raspberry: raspberry });
      });
  }

  deleteMetric(id) {
    console.log("delete " + id);
    fetch(SERVER_URL + "/metric/" + id, {
      method: "DELETE",
      headers: new Headers({
        token: this.state.token,
        Accept: "application/json",
        "Content-Type": "application/json",
        Origin: "",
      }),
    }).then((res) => {
      if (res.status === 200) {
        // return res.json()
      }
    });
  }

  deleteInput(id) {
    console.log("delete " + id);
    fetch(SERVER_URL + "/input/" + id, {
      method: "DELETE",
      headers: new Headers({
        token: this.state.token,
        Accept: "application/json",
        "Content-Type": "application/json",
        Origin: "",
      }),
    }).then((res) => {
      if (res.status === 200) {
        // return res.json()
      }
    });
  }

  toggleInput(input) {
    console.log(input);
    fetch(SERVER_URL + "/input/" + input.ObjectID, {
      method: "PATCH",
      body: JSON.stringify({ state: !input.state }),
      headers: new Headers({
        token: this.state.token,
        Accept: "application/json",
        "Content-Type": "application/json",
        Origin: "",
      }),
    }).then((res) => {
      if (res.status === 200) {
        this.loadInputs(this.props.match.params.id);
        // return res.json()
      }
    });
  }

  deleteRaspberry(id) {
    console.log("delete " + id);
    fetch(SERVER_URL + "/raspberry/" + id, {
      method: "DELETE",
      headers: new Headers({
        token: this.state.token,
        Accept: "application/json",
        "Content-Type": "application/json",
        Origin: "",
      }),
    }).then((res) => {
      if (res.status === 200) {
        return res.json();
      }
    });
  }

  loadInputs(id) {
    raspberryHandler.getInputs(id).then((inputs) => {
      console.log("CACA", inputs);
      this.setState({ inputs: inputs });
    });
  }

  reloadRaspberryMetrics(id) {
    raspberryHandler.getMetrics(id).then((metrics) => {
      this.setState({ metrics: metrics });
    });
  }

  handleChangeFields = (event) => {
    this.setState({
      [event.target.id]: event.target.value,
    });
  };

  render() {
    if (this.state.raspberry === undefined) {
      return <div>Loading</div>;
    }

    return (
      <div className="Dashboard">
        <h1>Raspberry {this.state.raspberry.name}</h1>
        {this.state.edit && (
          <div className="Create">
            <form onSubmit={this.createInput}>
              <h1>Input</h1>
              <FormGroup controlId="inputTitle" bsSize="large">
                <FormLabel>Title</FormLabel>
                <FormControl
                  value={this.state.inputTitle}
                  onChange={this.handleChangeFields}
                  type="text"
                />
              </FormGroup>
              <FormGroup controlId="inputCode" bsSize="large">
                <FormLabel>Code</FormLabel>
                <FormControl
                  value={this.state.inputCode}
                  onChange={this.handleChangeFields}
                  type="text"
                />
              </FormGroup>
              <Button
                block
                bsSize="large"
                disabled={!this.validateInputForm()}
                type="submit"
              >
                Create
              </Button>
            </form>
            <form onSubmit={this.createMetric}>
              <h1>Output</h1>
              <FormGroup controlId="title" bsSize="large">
                <FormLabel>Title</FormLabel>
                <FormControl
                  value={this.state.title}
                  onChange={this.handleChangeFields}
                  type="text"
                />
              </FormGroup>
              <FormGroup controlId="type" bsSize="large">
                <FormLabel>Type</FormLabel>
                <FormControl
                  value={this.state.type}
                  onChange={this.handleChangeFields}
                  type="text"
                />
              </FormGroup>
              <FormGroup controlId="code" bsSize="large">
                <FormLabel>Code</FormLabel>
                <FormControl
                  value={this.state.code}
                  onChange={this.handleChangeFields}
                  type="text"
                />
              </FormGroup>
              <Button
                block
                bsSize="large"
                disabled={!this.validateForm()}
                type="submit"
              >
                Create
              </Button>
            </form>
          </div>
        )}

        {this.state.metrics.map((metric) => {
          if (metric.data === undefined || metric.data === null) {
            return (
              <h4 key={metric.id}>
                <Link
                  to={{
                    pathname: "/metric/" + metric.id,
                    state: { raspberry: this.state.raspberry },
                  }}
                >
                  {metric.title}
                </Link>{" "}
                {this.state.edit && metric.code}
                <button onClick={this.deleteMetric.bind(this, metric.id)}>
                  Delete
                </button>
              </h4>
            );
          } else if (metric.data.State === undefined) {
            return (
              <h4 key={metric.id}>
                <Link
                  to={{
                    pathname: "/metric/" + metric.id,
                    state: { raspberry: this.state.raspberry },
                  }}
                >
                  {metric.title}
                </Link>{" "}
                {this.state.edit && metric.code}
                {metric.data.Value}{" "}
                {this.state.edit && (
                  <button onClick={this.deleteMetric.bind(this, metric.id)}>
                    Delete
                  </button>
                )}
              </h4>
            );
          } else {
            return (
              <h4 key={metric.id}>
                <Link
                  to={{
                    pathname: "/metric/" + metric.id,
                    state: { raspberry: this.state.raspberry },
                  }}
                >
                  {metric.title}
                </Link>{" "}
                {this.state.edit && metric.code} {metric.data.State.toString()}{" "}
                {this.state.edit && (
                  <button onClick={this.deleteMetric.bind(this, metric.id)}>
                    Delete
                  </button>
                )}
              </h4>
            );
          }
        })}

        {this.state.inputs.map((input) => (
          <h4 key={input.ObjectID}>
            {input.title} {this.state.edit && input.code}
            {input.state}
            <Switch
              onChange={this.toggleInput.bind(this, input)}
              checked={input.state}
            />
            {this.state.edit && (
              <button onClick={this.deleteInput.bind(this, input.ObjectID)}>
                Delete
              </button>
            )}
          </h4>
        ))}
      </div>
    );
  }
}
