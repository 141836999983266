import React, { Component } from "react";
import Cookies from "js-cookie";
//import ReactEcharts from 'echarts-for-react';
import "./Metric.css";
import Select from "react-dropdown-select";
import Chart from "react-google-charts";

const SERVER_URL = "https://api.aerotoulousain.fr";

export default class Metric extends Component {
  constructor(props) {
    super(props);

    const { raspberry } = props.location.state;
    console.log(raspberry);

    this.state = {
      metric: props.metric,
      token: Cookies.get("token"),
      title: "",
      data: [],
      type: "",
      name: "",
      raspberry: raspberry,
      password: "",
      raspberries: [],
      value: 0,
      select_values: [{ value: "hour", label: "hour" }],
      granularity: "hour",
      date: new Date(),
      now: new Date(),
      loading: true,
    };
    this.deleteMetric = this.deleteMetric.bind(this);
  }

  componentDidMount() {
    // this.timerID = setInterval(() => this.tick(), 1000);
  }

  componentWillUnmount() {
    clearInterval(this.timerID);
  }

  validateForm() {
    return (
      this.state.title.length > 0 &&
      this.state.type.length > 0 &&
      this.state.code.length > 0
    );
  }

  componentWillMount = (event) => {
    this.reloadData(this.props.match.params.id, this.state.granularity);
  };

  // reloadData(id, granularity) {
  //   this.setState({ loading: true });
  //   const from = new Date().toUTCString();
  //   const to = new Date().toUTCString();
  //   fetch(
  //     `${SERVER_URL}/metric/${id}/data?limit=${100}&granularity=${granularity}&from=${from}&to=${to}`,
  //     {
  //       method: "GET",
  //       headers: new Headers({
  //         token: this.state.token,
  //         Accept: "application/json",
  //         "Content-Type": "application/json",
  //         Origin: "",
  //       }),
  //     }
  //   )
  //     .then((res) => {
  //       if (res.status === 200) {
  //         return res.json();
  //       }
  //     })
  //     .then((data) => {
  //       var elems = [
  //         ["date", "valeur"],
  //         // ['2013', 1000],
  //         // ['2014', 1170],
  //         // ['2015', 660],
  //         // ['2016', 1030],
  //       ];

  //       var value = 0;
  //       if (data.length > 0) {
  //         value = data[data.length - 1].average_value;
  //       }

  //       data.forEach((elem) => {
  //         console.log(elem.average_value);
  //         const created_at = elem.created_at;
  //         console.log(created_at);
  //         if (elem.value && elem.value.State) {
  //           elems.push([created_at, elem.value.State ? 1 : 0]);
  //         } else {
  //           elems.push([created_at, elem.average_value]);
  //         }
  //       });
  //       // elems.reverse();
  //       this.setState({
  //         data: elems,
  //         value: value,
  //         loading: false,
  //       });
  //     });
  // }

  reloadData(id, granularity) {
    this.setState({ loading: true });
    const from = new Date().toUTCString();
    const to = new Date().toUTCString();
    fetch(`${SERVER_URL}/es/data`, {
      method: "POST",
      body: JSON.stringify({
        metric_id: id,
        granularity: granularity,
      }),
      headers: new Headers({
        token: this.state.token,
        Accept: "application/json",
        "Content-Type": "application/json",
        Origin: "",
      }),
    })
      .then((res) => {
        if (res.status === 200) {
          return res.json();
        }
      })
      .then((res) => {
        console.log(res);
        const data = res.aggregations.values_by_hour.buckets;

        var elems = [
          ["date", "valeur"],
          // ['2013', 1000],
          // ['2014', 1170],
          // ['2015', 660],
          // ['2016', 1030],
        ];

        var value = 0;
        if (data.length > 0) {
          value = data[data.length - 1].avg_value.value;
        }

        data.forEach((elem) => {
          console.log(elem.avg_value.value);
          const created_at = elem.key_as_string;
          console.log(created_at);
          elems.push([created_at, elem.avg_value.value]);
        });
        // elems.reverse();
        this.setState({
          data: elems,
          value: value,
          loading: false,
        });
      });
  }
  deleteMetric(id) {
    console.log("delete " + id);
    fetch(SERVER_URL + "/metric/" + id, {
      method: "DELETE",
      headers: new Headers({
        token: this.state.token,
        Accept: "application/json",
        "Content-Type": "application/json",
        Origin: "",
      }),
    }).then((res) => {
      if (res.status === 200) {
        return res.json();
      }
    });
  }

  toggleMetric = (event) => {
    if (this.state.metric === undefined) {
      return;
    }

    this.setState({ state: !this.state.state });
    fetch(
      SERVER_URL + "/raspberry/" + this.state.raspberry.ObjectID + "/message",
      {
        method: "POST",
        body: JSON.stringify({
          data: this.state.metric.code + " " + (!this.state.state ? "1" : "0"),
        }),
        headers: new Headers({
          token: this.state.token,
          Accept: "application/json",
          "Content-Type": "application/json",
          Origin: "",
        }),
      }
    ).then((res) => {
      if (res.status === 200) {
        return res.json();
      }
    });
  };

  handleChangeFields = (event) => {
    this.setState({
      [event.target.id]: event.target.value,
    });
  };

  render() {
    if (this.state.data === undefined) {
      return;
    }

    console.log(this.state.data.length);

    const options = [
      { value: "year", label: "year" },
      { value: "month", label: "month" },
      { value: "day", label: "day" },
      { value: "hour", label: "hour" },
      { value: "minute", label: "minute" },
      { value: "second", label: "second" },
    ];

    return (
      <div className="Metric">
        <h1>Valeur actuelle : {this.state.value}</h1>

        <Select
          options={options}
          values={this.state.select_values}
          onChange={(values) => {
            this.setState({
              granularity: values[0].value,
              select_values: values,
            });
            this.reloadData(this.props.match.params.id, values[0].value);
          }}
        />

        <div className="Graphic">
          {this.state.loading && <div class="loader"></div>}
          {!this.state.loading && (
            <Chart
              width={1500}
              height={"500px"}
              chartType="AreaChart"
              loader={<div>Loading Chart</div>}
              data={this.state.data}
              options={{
                title: "Company Performance",
                hAxis: { title: "", titleTextStyle: { color: "#333" } },
                vAxis: { minValue: 0 },
                // For the legend to fit, we make the chart area smaller
                chartArea: { width: "50%", height: "70%" },
                // lineWidth: 25
              }}
            />
          )}
        </div>
      </div>
    );
  }
}
