import React, { useState } from "react";
import Cookies from "universal-cookie";
import { Button, FormGroup, FormControl, FormLabel } from "react-bootstrap";
import "./Signup.css";

//const PROXY = '127.0.0.1:8080/';
const SERVER_URL = "https://api.aerotoulousain.fr";

export default function Signup({ history }) {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const validateForm = () => {
    return email.length > 0 && password.length > 0;
  };

  const handleChangeEmail = (event) => {
    setEmail(event.target.value);
  };

  const handleChangePassword = (event) => {
    setPassword(event.target.value);
  };

  const handleSubmit = (event) => {
    console.log("toto"); //

    event.preventDefault();
    console.log("toto"); //

    fetch(SERVER_URL + "/user", {
      method: "POST",
      body: JSON.stringify({ email: email, password: password }),
    })
      .then((res) => {
        if (res.status === 200) {
          console.log("resJson");
          console.log(res);
        }

        return res.json();
      })
      .then((resJson) => {
        console.log(resJson);
        var token = resJson.Token.value;
        const cookies = new Cookies();
        cookies.set("token", token, { path: "/" });
        cookies.set("password", password, { path: "/" });
        history.push("/");
      });
  };

  return (
    <div className="Signup">
      <form onSubmit={handleSubmit}>
        <FormGroup controlId="email" bsSize="large">
          <FormLabel>Email</FormLabel>
          <FormControl
            autoFocus
            type="email"
            value={email}
            onChange={handleChangeEmail}
          />
        </FormGroup>
        <FormGroup controlId="password" bsSize="large">
          <FormLabel>Password</FormLabel>
          <FormControl
            value={password}
            onChange={handleChangePassword}
            type="password"
          />
        </FormGroup>
        <Button block bsSize="large" disabled={!validateForm()} type="submit">
          Signup
        </Button>
      </form>
    </div>
  );
}
