import api from "./api";

const raspberryHandler = {
  getInputs(raspberryID) {
    return api.get(`/raspberry/${raspberryID}/inputs`).then((res) => {
      return res.data;
    });
  },

  getMetrics(raspberryID) {
    return api.get(`/raspberry/${raspberryID}/metrics`).then((res) => {
      return res.data;
    });
  },
};

export default raspberryHandler;
