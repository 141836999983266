import React, { useCallback, useEffect, useState } from "react";
import Cookies from "universal-cookie";
import { Link } from "react-router-dom";
import { Button, FormGroup, FormControl, FormLabel } from "react-bootstrap";
import "./Home.css";
// var CanvasJSReact = require('./canvasjs.react');
// var CanvasJSChart = CanvasJSReact.CanvasJSChart;

const SERVER_URL = "https://api.aerotoulousain.fr";

function isArray(what) {
  return Object.prototype.toString.call(what) === "[object Array]";
}

export default function Home() {
  const cookies = new Cookies();

  const token = cookies.get("token");
  const [title, setTitle] = useState("");
  const [items, setItems] = useState([]);

  const handleChange = (event) => {
    setTitle(event.target.value);
  };

  const validateForm = () => {
    return title.length > 0;
  };

  const reloadDashboards = useCallback(() => {
    fetch(SERVER_URL + "/dashboards", {
      method: "GET",
      headers: new Headers({
        token: token,
        Accept: "application/json",
        "Content-Type": "application/json",
        Origin: "",
      }),
    })
      .then((res) => {
        if (res.status === 200) {
          return res.json();
        }
      })
      .then((resJson) => {
        if (!isArray(resJson)) {
          return;
        }
        var items = [];
        for (var i = 0; i < resJson.length; i++) {
          var id = resJson[i].ObjectID;
          console.log(id);
          items.push(
            <h2 key={i}>
              <Link to={"/dashboard/" + id}>{resJson[i].title}</Link>
            </h2>
          );
        }
        setItems(items);
      });
  }, [token]);

  useEffect(() => {
    reloadDashboards();
  }, [reloadDashboards]);

  const handleSubmit = (event) => {
    event.preventDefault();
    fetch(SERVER_URL + "/dashboard", {
      method: "POST",
      body: JSON.stringify({ title: title }),
      headers: new Headers({
        token: token,
        Accept: "application/json",
        "Content-Type": "application/json",
        Origin: "",
      }),
    }).then((res) => {
      if (res.status === 200) {
        reloadDashboards();
      }
    });
  };

  return (
    <div className="Home">
      <h1>Home</h1>

      <div className="Create">
        <h2>Create dashboard</h2>
        <form onSubmit={handleSubmit}>
          <FormGroup controlId="title" bsSize="large">
            <FormLabel>Title</FormLabel>
            <FormControl value={title} onChange={handleChange} type="text" />
          </FormGroup>
          <Button block bsSize="large" disabled={!validateForm()} type="submit">
            Create
          </Button>
        </form>
      </div>

      <div className="Dashboards">
        <h1>Dashboards</h1>
        {items}
      </div>
    </div>
  );
}
