import React, { useState } from "react";
import JSONInput from "react-json-editor-ajrm";
import JSONPretty from "react-json-pretty";
import locale from "react-json-editor-ajrm/locale/en";
const theme = require("react-json-pretty/dist/1337");

function JsonComponent() {
  const [open, setOpen] = useState(false);

  const sampleObject = {
    test: "hello",
  };

  return (
    <>
      {open ? (
        <div>
          <textarea rows="5" cols="100" name="description"></textarea>
          <input
            type="submit"
            onClick={() => {
              setOpen(!open);
            }}
          />
        </div>
      ) : (
        <JSONPretty
          onClick={() => {
            setOpen(!open);
          }}
          data={sampleObject}
          theme={theme}
          onJSONPrettyError={(e) => console.error(e)}
        ></JSONPretty>
      )}
    </>
  );
}

export default JsonComponent;
