import React, { Component } from "react";
import { Button, FormGroup, FormControl, FormLabel } from "react-bootstrap";
import Cookies from "js-cookie";
import { Link } from "react-router-dom";
import "./Dashboard.css";

const SERVER_URL = "https://api.aerotoulousain.fr";

function isArray(what) {
  return Object.prototype.toString.call(what) === "[object Array]";
}

export default class Dashboard extends Component {
  constructor(props) {
    super(props);

    this.state = {
      token: Cookies.get("token"),
      dashboard: {
        title: "",
      },
      title: "",
      type: "",
      code: "",
      name: "",
      password: "",
      raspberries: [],
      metrics: [],
      items: [],
      date: new Date(),
    };
    this.deleteMetric = this.deleteMetric.bind(this);
  }

  createMetric = (event) => {
    event.preventDefault();
    fetch(SERVER_URL + "/raspberry/" + this.props.match.params.id + "/metric", {
      method: "POST",
      body: JSON.stringify({
        title: this.state.title,
        code: this.state.code,
        type: this.state.type,
      }),
      headers: new Headers({
        token: this.state.token,
        Accept: "application/json",
        "Content-Type": "application/json",
        Origin: "",
      }),
    })
      .then((res) => {
        if (res.status === 200) {
          return res.json();
        }
      })
      .then((resJson) => {});
  };

  createRaspberry = (event) => {
    event.preventDefault();
    fetch(
      SERVER_URL + "/dashboard/" + this.props.match.params.id + "/raspberry",
      {
        method: "POST",
        body: JSON.stringify({
          name: this.state.name,
          password: this.state.password,
        }),
        headers: new Headers({
          token: this.state.token,
          Accept: "application/json",
          "Content-Type": "application/json",
          Origin: "",
        }),
      }
    )
      .then((res) => {
        if (res.status === 200) {
          this.reloadRaspberries(this.props.match.params.id);
          return res.json();
        }
      })
      .then((resJson) => {});
  };

  componentWillUnmount() {
    clearInterval(this.timerID);
  }

  validateForm() {
    return this.state.name.length > 0 && this.state.password.length > 0;
  }

  componentWillMount = (event) => {
    this.reloadRaspberries(this.props.match.params.id);
    this.reloadDashboard(this.props.match.params.id);
  };

  reloadRaspberries(id) {
    fetch(SERVER_URL + "/dashboard/" + id + "/raspberries", {
      method: "GET",
      headers: new Headers({
        token: this.state.token,
        Accept: "application/json",
        "Content-Type": "application/json",
        Origin: "",
      }),
    })
      .then((res) => {
        if (res.status === 200) {
          return res.json();
        }
      })
      .then((raspberries) => {
        this.setState({ raspberries: raspberries });

        var items = [];
        for (var i = 0; i < raspberries.length; i++) {
          var id = raspberries[i].ObjectID;
          items.push(
            <h2 key={i}>
              <Link to={"/raspberry/" + id + "?edit=false"}>
                {raspberries[i].name}
              </Link>
            </h2>
          );
        }
        this.setState({ items: items });
      });
  }

  reloadDashboard(id) {
    fetch(SERVER_URL + "/dashboard/" + id, {
      method: "GET",
      headers: new Headers({
        token: this.state.token,
        Accept: "application/json",
        "Content-Type": "application/json",
        Origin: "",
      }),
    })
      .then((res) => {
        if (res.status === 200) {
          return res.json();
        }
      })
      .then((dashboard) => {
        this.setState({ dashboard: dashboard });
      });
  }

  deleteMetric(id) {
    console.log("delete " + id);
    fetch(SERVER_URL + "/metric/" + id, {
      method: "DELETE",
      headers: new Headers({
        token: this.state.token,
        Accept: "application/json",
        "Content-Type": "application/json",
        Origin: "",
      }),
    }).then((res) => {
      if (res.status === 200) {
        return res.json();
      }
    });
  }

  deleteRaspberry(id) {
    console.log("delete " + id);
    fetch(SERVER_URL + "/raspberry/" + id, {
      method: "DELETE",
      headers: new Headers({
        token: this.state.token,
        Accept: "application/json",
        "Content-Type": "application/json",
        Origin: "",
      }),
    }).then((res) => {
      if (res.status === 200) {
        return res.json();
      }
    });
  }

  reloadDashboardMetrics(id) {
    fetch(SERVER_URL + "/dashboard/" + id + "/metrics", {
      method: "GET",
      headers: new Headers({
        token: this.state.token,
        Accept: "application/json",
        "Content-Type": "application/json",
        Origin: "",
      }),
    })
      .then((res) => {
        if (res.status === 200) {
          return res.json();
        }
      })
      .then((resJson) => {
        if (!isArray(resJson)) {
          return;
        }
        var metrics = [];
        for (var i = 0; i < resJson.length; i++) {
          var metric = resJson[i];
          metrics.push(
            <h2 key={i}>
              {metric.title} {metric.type} {metric.code}{" "}
              {JSON.stringify(metric.data)}{" "}
              <button onClick={this.deleteMetric.bind(this, metric.ObjectID)}>
                Delete
              </button>
            </h2>
          );
        }
        this.setState({ metrics: metrics });
      });

    fetch(SERVER_URL + "/dashboard/" + id + "/raspberries", {
      method: "GET",
      headers: new Headers({
        token: this.state.token,
        Accept: "application/json",
        "Content-Type": "application/json",
        Origin: "",
      }),
    })
      .then((res) => {
        if (res.status === 200) {
          return res.json();
        }
      })
      .then((resJson) => {
        if (!isArray(resJson)) {
          return;
        }
        var raspberries = [];
        for (var i = 0; i < resJson.length; i++) {
          var raspberry = resJson[i];
          raspberries.push(
            <h2 key={i}>
              {raspberry.name} {raspberry.last_contact}
              <button
                onClick={this.deleteRaspberry.bind(this, raspberry.ObjectID)}
              >
                Delete
              </button>
            </h2>
          );
        }
        this.setState({ raspberries: raspberries });
      });
  }

  handleChangeFields = (event) => {
    this.setState({
      [event.target.id]: event.target.value,
    });
  };

  render() {
    return (
      <div className="Dashboard">
        <h1>Dashboard {this.state.dashboard.title}</h1>
        <div className="Create">
          <form onSubmit={this.createRaspberry}>
            <FormGroup controlId="name" bsSize="large">
              <FormLabel>Name</FormLabel>
              <FormControl
                value={this.state.name}
                onChange={this.handleChangeFields}
                type="text"
              />
            </FormGroup>

            <FormGroup controlId="password" bsSize="large">
              <FormLabel>Password</FormLabel>
              <FormControl
                value={this.state.password}
                onChange={this.handleChangeFields}
                type="password"
              />
            </FormGroup>
            <Button
              block
              bsSize="large"
              disabled={!this.validateForm()}
              type="submit"
            >
              Create
            </Button>
          </form>
        </div>
        {this.state.items}
      </div>
    );
  }
}
