import React from 'react';
import {Link} from 'react-router-dom';
import './Nav.css';

export default function Nav() {
  return (
    <nav>
      <h3>Logo</h3>
      <ul className="nav-links">
        <Link to='/'>
          <li>Home</li>
        </Link>
        <Link to='/signup'>
          <li>Signup</li>
        </Link>
        <Link to='/login'>
          <li>Login</li>
        </Link>
      </ul>
    </nav>
  );
}
