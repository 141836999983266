import axios from "axios";
import Cookies from "universal-cookie";

const cookies = new Cookies();
const token = cookies.get("token");
axios.defaults.headers.common["token"] = token;

export default axios.create({
  baseURL: process.env.REACT_APP_BACKEND_URL,
});
